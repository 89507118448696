<footer class="astrea-footer py-4 px-2">

    <div class="container">

        <div class="d-flex j-c-around flex-wrap">
            <div class="astrea-logo">
                <img class="img-responsive" src="/assets/images/astrea-brand/logo_web_white.png" alt="{{ 'logo' | translate }}">
            </div>
            <div class="mb-3">
                <h4>{{ 'footer.about' | translate }}</h4>
                <ul>
                    <li><a routerLink="/recent" title="{{ 'navigation.recent' | translate }}">{{ 'navigation.recent' | translate }}</a></li>
                    <li><a routerLink="/authors" title="{{ 'navigation.authors' | translate }}">{{ 'navigation.authors' | translate }}</a></li>
                    <li><a routerLink="/collections" title="{{ 'navigation.collections' | translate }}">{{ 'navigation.collections' | translate }}</a></li>
                    <li><a routerLink="/materias" title="{{ 'navigation.matter' | translate }}">{{ 'navigation.matter' | translate }}</a></li>
                </ul>
            </div>

            <div class="mb-3">
                <h4>{{ 'footer.help' | translate }}</h4>
                <ul>
                    <li><a routerLink="/about/history" title="{{ 'navigation.editorial.editorial' | translate }}">{{ 'navigation.editorial.editorial' | translate }}</a></li>
                    <li><a routerLink="/astreavirtual" title="Astrea Virtual">Astrea Virtual</a></li>
                    <li><a routerLink="/contact" title="{{ 'navigation.contact' | translate }}">{{ 'navigation.contact' | translate }}</a></li>
                </ul>
            </div>

            <div class="footer-social d-flex flex-column j-c-start a-i-start">
                <p><strong>{{ 'footer.social' | translate }}</strong></p>
                <div class="mt-3">
                    <a href="https://www.facebook.com/EditorialAstrea" target="_blank">
                        <img src="/../assets/images/social/icon-fb.svg" width="30px" height="30px" alt="Astrea Facebook">
                    </a>
                    <a href="https://www.instagram.com/EditorialAstrea" target="_blank">
                        <img src="/../assets/images/social/icon-ig.svg" width="30px" height="30px" alt="Astrea Instagram">
                    </a>
                    <a href="https://twitter.com/EditorialAstrea" target="_blank">
                        <img src="/../assets/images/social/icon-tw.svg" width="30px" height="30px" alt="Astrea Twitter">
                    </a>
                </div>
            </div>
        </div>

        <div class="footer-slogan d-flex j-c-around a-i-center mt-4 w-100">
            <p class="t-center">Copyright © {{ currentYear }} Editorial Astrea</p>
            <div class="d-flex j-c-between a-i-center">
                <a routerLink="/terms" title="{{ 'terms.title' | translate }}">{{ 'terms.title' | translate }}</a>
                <a routerLink="/policy" title="{{ 'policy.title' | translate }}">{{ 'policy.title' | translate }}</a>
            </div>
        </div>

    </div>

</footer>
