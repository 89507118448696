<div class="editorial-astrea">

    <mat-drawer-container class="astrea-container">

        <section class="astrea-header">
            <mat-toolbar>
                <astrea-menu-navbar
                    (handleMenu)="handleMenu()"
                ></astrea-menu-navbar>
            </mat-toolbar>
        </section>

        <section class="astrea-content">
            <router-outlet></router-outlet>
        </section>

        <br>

        <section>
            <astrea-footer></astrea-footer>
        </section>


        <!-- Menu Mobile -->
        <mat-drawer #sidenav [mode]="'over'" fxHide fxHide.sm="false" fxHide.xs="false">
            <astrea-menu-leftnav
                (handleMenu)="handleMenu()"
            ></astrea-menu-leftnav>
        </mat-drawer>


    </mat-drawer-container>

    <div *ngIf="devMode != 'production'" class="development-mode">
        <p>Development</p>
    </div>

</div>
