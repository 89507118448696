import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CartStorage } from '@shared/services/cart-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getBooks(books: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/doctrine/web/cart`, { books: books });
  }

  getUser(): Observable<any> {
    return this.http.get(`${environment.URL_API_AV}/api/user/me`);
  }

  putUser(data: any): Observable<any> {
    return this.http.put(`${environment.URL_API_AV}/api/user/me/update`, data);
  }

  getUserShipment(books: CartStorage[]): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/me/shipment`, { books: books });
  }


  /* ****************************************
                  MERCADO PAGO
  **************************************** */

  generatePayMP_Physical(payload: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/payments/mercadopago/physical`, payload);
  }

  generatePayMP_Digital(books: CartStorage[], account: string): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/payments/mercadopago/digital`, { books, account });
  }


  /* ****************************************
                    CASH
  **************************************** */

  registryPurchaseCash(books: CartStorage[], category: string): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/payments/cash/registry`, { books, category });
  }

  getPurchaseCashDetails(purchaseId: string): Observable<any> {
    return this.http.get(`${environment.URL_API_AV}/api/payments/cash/web/${purchaseId}`);
  }


  /* ****************************************
                   PAYPAL
  **************************************** */

  registryPurchasePaypalDigital(payload: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/payments/paypal/digital`, payload);
  }

  registryPurchasePaypalPhysical(payload: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/user/payments/paypal/physical`, payload);
  }

}
