/* *******************************************************
    SERVICIO DE FUNCIONES AUXILIARES Y OTROS EXPORTS
******************************************************* */

import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
    private _t: TranslateService
  ) { }


  /* ********** Manejo de Passwords ********** */
  static validatePassword(control: AbstractControl) {
    const pass = control.value;
    if (!containsNumber(pass) || !containsLetter(pass)) {
      return { invalid_password: true };
    }
    return null;
  }
  static matchPasswords(control: AbstractControl) {
    const password = control.get('newPass')!.value;
    const confirmPassword = control.get('confirmPass')!.value;
    if (password === confirmPassword) {
      return null;
    }
    return { match_password: true };
  }
  /* ********* END Manejo de Passwords ******** */

  static matchUser(control: AbstractControl) {
    const user = control.get('user')!.value;
    const confirmUser = control.get('confirmUser')!.value;
    if (user === confirmUser) {
      return null;
    }
    return { match_user: true };
  }


  // Limpiar texto con expresiones regulares
  static clearText(text: string): string {
    let newText = text.replace(/(#[0-9]*)/g, '');
    newText = newText.replace(/[&#+$~%*{}]/g, '');
    newText = newText.replace(/(\/\/\/*)/g, '');
    return newText;
  }

  accentsIgnore(text: string): string {
    return text.toLocaleLowerCase()
      .replace(/[aá]/g, '[a,á]')
      .replace(/[eé]/g, '[e,é]')
      .replace(/[ií]/g, '[i,í]')
      .replace(/[oó]/g, '[o,ó]')
      .replace(/[uúü]/g, '[u,ú,ü]');
  }

  // Función para limpiar artículos, pronombres y preposiciones de un texto
  static clearTextStopWords(text: string): string {

    let newText = '';
    const arrAux: any[] = [];

    // Limpiamos números y caracteres especiales
    newText = text.replace(/([0-9])/g, '');
    newText = newText.replace(/[.,^:"/&#+$~%*(){}]/g, '');
    newText = newText.toLowerCase();
    const arrWords = newText.split(' ');

    // Limpiamos palabras de 3 caracteres o menos y algunas otras palabras
    arrWords.forEach(word => {
      if (word.length > 3 && word !== 'para' && word !== 'como'  && word !== 'ante' && word !== 'este' && word !== 'esta' && word !== 'sobre' &&
        word !== 'estas' && word !== 'estos' && word !== 'ella' && word !== 'ello' && word !== 'pena' &&
        word !== 'cámara' && word !== 'cámar' && word !== 'federal' && word !== 'nación' && word !== 'tribunal' && word !== 'código' &&
        word !== 'tribunales' && word !== 'nacional' && word !== 'casación' && word !== 'corte' && word !== 'suprema' &&
        word !== 'apelaciones' && word !== 'apelación' && word !== 'procesal' && word !== 'penal' && word !== 'civil')
      {
        arrAux.push(word);
      }
    });

    // Borramos palabras repetidas del array
    const finalWords = [...new Set(arrAux)];
    const finalText = finalWords.join(' ');

    return finalText;
  }


  // Obtener fecha de hoy
  static getCurrentDate() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }


  /* ********** NUMBERS ********** */

  getOrdinalNumber(edition: number): string {

    let n = edition;
    if (n > 10) {
      // Si tiene dos dígitos, tomamos el último solamente
      const l = edition.toString();
      n = parseInt(l.split('')[l.toString().length-1]);
    }

    if (this._t.currentLang == 'en-us') {
      switch(n) {
        case 1 : { return 'st'; }
        case 2 : { return 'nd'; }
        case 3 : { return 'rd'; }
        case 4 : case 5 : case 6 : case 7 : case 8 : case 9 : case 10 : { return 'th'; }
        default : { return ''; }
      }
    } else {
      switch(n) {
        case 1 : { return 'ra'; }
        case 2 : { return 'da'; }
        case 3 : { return 'ra'; }
        case 4 : case 5 : case 6 : { return 'ta'; }
        case 7 : { return 'ma'; }
        case 8 : { return 'va'; }
        case 9 : { return 'na'; }
        case 10 : { return 'ma'; }
        default : { return ''; }
      }
    }

  }

}

// Función para saber si un texto contiene una letra
function containsLetter(texto: string){
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  texto = texto.toLowerCase();
  for (let i = 0; i < texto.length; i++) {
    if (letters.indexOf(texto.charAt(i), 0) !== -1){
      return true;
    }
  }
  return false;
}


// Función para saber si un texto contiene un número
function containsNumber(pass: string) {
  const numbers = '0123456789';
  for (let i = 0; i < pass.length; i++){
    if (numbers.indexOf(pass.charAt(i), 0) !== -1){
      return true;
    }
  }
  return false;
}
