import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { currencyAction, languageAction, emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'astrea-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styleUrls: ['./menu-navbar.component.scss']
})
export class MenuNavbarComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Output() handleMenu = new EventEmitter<any>();

  public isLoggedIn$!: Observable<boolean>;
  private auth$!: Subscription;

  public language = 'es-ar';
  private language$!: Subscription;

  public currency = 'ARS';
  private currency$!: Subscription;

  public userName!: string;
  private subsUser$!: Subscription;

  public formSearch = this.formBuilder.group({
    q: ['', Validators.required],
  });

  public cartStorageCount = '';
  private cartCount$!: Subscription;

  public redirect = '/home';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private authFirebaseService: AuthFirebaseService,
    private _t: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getUserName();
    this.userIsLoggedIn();
    this.getCartStorageCount();
    this.language$ = this.store.select(state => state.storeConfig.language)
      .subscribe(lang => {
        if (this.language != lang) {
          this.language = lang;
          this.handleLanguage();
        }
      });
    this.currency$ = this.store.select(state => state.storeConfig.currency)
      .subscribe(c => {
        if (this.currency != c) {
          this.currency = c;
          this.handleCurrency();
        }
      });
    this.route.queryParams
      .subscribe(async (params: Params) => {
        this.redirect = params.redirect ? params.redirect : '/home';
      });
  }

  ngOnDestroy(): void {
    if (this.auth$) this.auth$.unsubscribe();
    if (this.language$) this.language$.unsubscribe();
    if (this.currency$) this.currency$.unsubscribe();
    if (this.subsUser$) this.subsUser$.unsubscribe();
    if (this.cartCount$) this.auth$.unsubscribe();
  }


  userIsLoggedIn() {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn$ = of(user === null ? false : true);
      });
  }

  getUserName(): void {
    this.subsUser$ = this.store.select(state => state.storeUser)
      .subscribe(userData => {
        if (userData.user) {
          this.userName = userData.user.firstname;
        }
      });
  }

  getCartStorageCount(): void {
    this.cartCount$ = this.store.select(state => state.storeConfig.cartCount)
      .subscribe(cartCount => {
        this.cartStorageCount = cartCount;
      });
  }

  handleMenuClose(): void {
    this.handleMenu.emit();
  }

  handleLanguage(): void {
    setTimeout(() => {
      switch (this.language) {
        case 'es-ar' : {
          this._t.use('es-ar');
          this.store.dispatch(languageAction({ language: 'es-ar' }));
          this.alertChange('El lenguaje cambió a Español - Argentina');
        };break;
        case 'en-us' : {
          this._t.use('en-us');
          this.store.dispatch(languageAction({ language: 'en-us' }));
          this.alertChange('The language changed to English');
        };break;
        default : {
          this._t.use('es-ar');
          this.store.dispatch(languageAction({ language: 'es-ar' }));
          this.alertChange('El lenguaje cambió a Español');
        };break;
      }
    }, 1);
  }

  handleCurrency(): void {
    setTimeout(() => {
      switch (this.currency) {
        case 'ARS' : {
          this.store.dispatch(currencyAction({ currency: 'ARS' }));
          this.alertChange('El precio de las obras se muestra en pesos argentinos (AR$)');
        };break;
        case 'USD' : {
          this.store.dispatch(currencyAction({ currency: 'USD' }));
          this.alertChange('El precio de las obras se muestra en dólares norteamericanos (U$D)');
        };break;
        case 'EUR' : {
          this.store.dispatch(currencyAction({ currency: 'EUR' }));
          this.alertChange('El precio de las obras se muestra en Euros (€UR)');
        };break;
        default : {
          this.store.dispatch(currencyAction({ currency: 'ARS' }));
          this.alertChange('El precio de las obras se muestra en pesos argentinos (AR$)');
        };break;
      }
    }, 1);
  }

  handleSearcher(): void {
    if (this.formSearch.get('q')?.value) {
      this.router.navigate(['/searcher'], { queryParams: { q: this.formSearch.get('q')?.value } });
    }
  }

  logout(): void {
    this.authFirebaseService.logout();
  }

  alertChange(message: string): void {
    this.store.dispatch(emitAlert({
      message: message,
      alertType: 'message'
    }));
  }

}
