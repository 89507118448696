import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

interface Queries {
  page?: string;
  q?: string;
  [key: string]: any;
}

interface Page {
  index: number;
  value: number;
}

@Component({
  selector: 'astrea-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  public countPages!: number;
  public currentPage = 0;
  public renderPages: Array<Page> = [];

  @Input() itemsPerPage!: number;
  @Input() totalLength!: number;
  @Input() pageRedirect!: string;

  public query: Queries = {};  // Objeto que utilizamos para propagar otros queryParams que necesita la página y mantener el filtro

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe((params: Params) => {

        if (params.q)
          this.query['q'] = params.q;

        // Dependiendo la página, guardamos uno u otro queryParams para propagar parámetros especiales que pueda necesitar
        if (this.pageRedirect == '/authors')
          this.query = { letter: params.letter ? params.letter : 'A' };

        this.currentPage = params.page ? (parseInt(params.page)-1) : 0;
        this.countPages = Math.ceil(this.totalLength / this.itemsPerPage);
        this.buildPaginator();
      });
  }

  buildPaginator(): void {
    this.renderPages = [];
    if (this.countPages == 0 || this.countPages == 1) {
      // Sin paginador
    }
    else if (this.countPages <= 5) {
      for (let page = 0; page < this.countPages; page++) {
        this.renderPages.push({ index: page, value: page+1 });
      }
    }
    else {
      let ellipsisStart = true;
      let ellipsisEnd = true;
      for (let page = 0; page < this.countPages; page++) {
        // Lógica para contruir el paginador con '...'
        if (page == 0) this.renderPages.push({ index: page, value: page+1 });
        else if (page > 0 && page <= this.countPages-2 && page < this.currentPage+2 && page > this.currentPage-2) this.renderPages.push({ index: page, value: page+1 });
        else if ((page == this.countPages-2 && this.currentPage < 1) || (page == 1 && this.countPages-2 < this.currentPage)) this.renderPages.push({ index: page, value: page+1 });
        else if (page > this.countPages-2 && page < this.countPages) this.renderPages.push({ index: page, value: page+1 });
        else if (ellipsisStart && (page < 3 || this.currentPage >= 3)) {
          ellipsisStart = false;
          this.renderPages.push({ index: 10000000, value: 10000000 });
        }
        else if (ellipsisEnd && page == this.countPages-2) {
          ellipsisEnd = false;
          this.renderPages.push({ index: 10000000, value: 10000000 });
        }
      }
    }
  }

  propagateQueryParams(page: number) {
    return { ...this.query, page: page };
  }

}
