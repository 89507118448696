/* ======================================
             INIT STATE NGRX
====================================== */

import { AlertType } from '@shared/data-structure/custom-types';
import { CURRENCY_WORTH } from '@shared/data-structure/currency-worth';


export interface StoreStateConfig {
  darkMode: boolean;
  language: string;
  currency: string;
  dollar: number;
  euro: number;
  maxamount: number;          // Precio máximo para cobrar envíos (Solo Argentina)
  shippingcost: number;       // Costos Argentina
  baseprice: number;          // Precio base para envío internacionales, se multiplica por el valor del dólar
  cartCount: string;
}
export interface StoreStateAlert {
  message: string;
  alertType: AlertType;
}
export interface StoreStateUser {
  user: any;
}
export interface StoreStateDataProvider {
  roles: any;
  lm: any;
}
export interface StoreStateCollections {
  collections: any;
}

export const initStoreStateConfig: StoreStateConfig = {
  darkMode: false,
  language: 'es-ar',
  currency: 'ARS',
  dollar: CURRENCY_WORTH,
  euro: CURRENCY_WORTH,
  maxamount: 0,
  shippingcost: 0,
  baseprice: 0,
  cartCount: '',
};
export const initStoreStateAlert: StoreStateAlert = {
  message: '',
  alertType: '',
};
export const initStoreStateUser: StoreStateUser = {
  user: null,
};
export const initStoreStateDataProvider: StoreStateDataProvider = {
  roles: null,
  lm: null,
};
export const initStoreStateCollections: StoreStateCollections = {
  collections: null,
};
