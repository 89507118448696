import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/* import { CustomPreloadingStrategyService } from '@shared/services/custom-preloading-strategy.service'; */
import { QuicklinkStrategy } from 'ngx-quicklink';

import { AuthenticationGuard } from '@shared/guards/authentication.guard';

import { CashSuccessMpComponent } from '@shared/components/@purchases/cash-success/cash-success.component';
import { MPSuccessComponent } from '@shared/components/@purchases/mp-success/mp-success.component';
import { PPSuccessComponent } from '@shared/components/@purchases/pp-success/pp-success.component';

import { MobileRedirectBookComponent } from '@shared/components/mobile-redirect-book/mobile-redirect-book.component';

export const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule),
    data: { preload: false },
  },
  {
    path: 'astreavirtual',
    loadChildren: () => import('./modules/astreavirtual/astreavirtual.module').then(m => m.AstreavirtualModule),
    data: { preload: false },
  },
  {
    path: 'author',
    loadChildren: () => import('./modules/author/author.module').then(m => m.AuthorModule),
    data: { preload: false },
  },
  {
    path: 'authors',
    loadChildren: () => import('./modules/authors/authors.module').then(m => m.AuthorsModule),
    data: { preload: false },
  },
  {
    path: 'book',
    loadChildren: () => import('./modules/book/book.module').then(m => m.BookModule),
    data: { preload: true },
  },
  {
    path: 'mobile_book/:id',
    component: MobileRedirectBookComponent,
    data: { preload: false },
  },
  {
    path: 'cart',
    loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule),
    data: { preload: true },
  },
  {
    path: 'collection',
    loadChildren: () => import('./modules/collection/collection.module').then(m => m.CollectionModule),
    data: { preload: false },
  },
  {
    path: 'collections',
    loadChildren: () => import('./modules/collections/collections.module').then(m => m.CollectionsModule),
    data: { preload: false },
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
    data: { preload: false },
  },
  {
    path: 'doctrine',
    loadChildren: () => import('./modules/doctrine/doctrine.module').then(m => m.DoctrineModule),
    data: { preload: true },
  },
  {
    path: 'editorial',
    loadChildren: () => import('./modules/editorial/editorial.module').then(m => m.EditorialModule),
    data: { preload: false },
  },
  {
    path: 'history',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    data: { preload: false },
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data: { preload: true },
  },
  {
    path: 'login',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: { preload: true },
  },
  {
    path: 'materia',
    loadChildren: () => import('./modules/materia/materia.module').then(m => m.MateriaModule),
    data: { preload: false },
  },
  {
    path: 'materias',
    loadChildren: () => import('./modules/materias/materias.module').then(m => m.MateriasModule),
    data: { preload: false },
  },
  {
    path: 'profile',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    data: { preload: false },
  },
  {
    path: 'policy',
    loadChildren: () => import('./modules/policy-privacy/policy-privacy.module').then(m => m.PolicyPrivacyModule),
    data: { preload: false },
  },
  {
    path: 'recent',
    loadChildren: () => import('./modules/recent/recent.module').then(m => m.RecentModule),
    data: { preload: false },
  },
  {
    path: 'register',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule),
    data: { preload: true },
  },
  {
    path: 'searcher',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    data: { preload: true },
  },
  {
    path: 'search',    // Repetimos módulo ya que /avbook está indexado en Google
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    data: { preload: true },
  },
  {
    path: 'store',
    loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule),
    data: { preload: true },
  },
  {
    path: 'terms',
    loadChildren: () => import('./modules/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
    data: { preload: false },
  },
  {
    path: 'wishlist',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/wishlist/wishlist.module').then(m => m.WishlistModule),
    data: { preload: false },
  },
  {
    path: '404',
    loadChildren: () => import('./modules/page-404/page-404.module').then(m => m.Page404Module),
    data: { preload: false },
  },
  {
    path: 'cash-success',
    component: CashSuccessMpComponent
  },
  {
    path: 'mp-success/:typePurchase',
    component: MPSuccessComponent
  },
  {
    path: 'pp-success/:typePurchase',
    component: PPSuccessComponent
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data: { preload: true },
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0]
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
