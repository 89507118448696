<div class="astrea-profile-invoicing">
    <mat-card class="p-2">
        <mat-card-content>
            <form [formGroup]="formInvoicing">

                <div class="astrea-subtitle my-2">
                    <h2>{{ 'profile.invoicing.invoicing' | translate }}</h2>
                    <p>
                        {{ 'profile.text_1' | translate }}
                    </p>
                </div>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <mat-form-field class="mr-0 mr-sm-1" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.business_name' | translate }}</mat-label>
                        <input type="text" matInput formControlName="businessName">
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="ml-0" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.cuit' | translate }}</mat-label>
                        <input type="text" matInput formControlName="cuit">
                        <mat-icon matSuffix>account_circle</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <mat-form-field class="mr-0 mr-sm-1" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.address' | translate }}</mat-label>
                        <input type="text" matInput formControlName="address">
                        <mat-icon matSuffix>room</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="ml-0" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.postalcode' | translate }}</mat-label>
                        <input type="text" matInput formControlName="postalCode">
                        <mat-icon matSuffix>code</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.invoicing.phone' | translate }}</mat-label>
                    <input type="number" matInput formControlName="phone">
                </mat-form-field>

                <mat-form-field *ngIf="formInvoicing.get('country')!.value == 'AR'" appearance="fill">
                    <mat-label>{{ 'profile.invoicing.iva_condition' | translate }}</mat-label>
                    <mat-select formControlName="ivaCondition">
                        <mat-option value="">Seleccionar</mat-option>
                        <mat-option *ngFor="let i of ivaCondition" value="{{ i.value }}">{{ i.text }}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.invoicing.country' | translate }}</mat-label>
                    <mat-select formControlName="country" (valueChange)="onCountryChange()">
                        <mat-option value="">Seleccionar</mat-option>
                        <mat-option *ngFor="let c of countries" value="{{ c.value }}">{{ c.text }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <!-- State (Provincia) solo para Argentina -->
                    <mat-form-field *ngIf="formInvoicing.get('country')!.value == 'AR'" class="mr-0 mr-sm-1" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.state' | translate }}</mat-label>
                        <mat-select formControlName="state">
                            <mat-option value="">Seleccionar</mat-option>
                            <mat-option *ngFor="let p of provinces" value="{{ p.value }}">{{ p.text }}</mat-option>
                        </mat-select>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="ml-0" appearance="fill">
                        <mat-label>{{ 'profile.invoicing.city' | translate }}</mat-label>
                        <input type="text" matInput formControlName="city">
                        <mat-icon matSuffix>location_city</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <button *ngIf="!loading" (click)="submitPersonalData()" class="btn btn-green mt-2 float-right" mat-button>
                    {{ 'save_changes' | translate }}
                </button>
                <button *ngIf="loading" class="btn btn-green mt-2 float-right" mat-button>
                    <astrea-loading></astrea-loading>
                </button>

            </form>
        </mat-card-content>
    </mat-card>
</div>
