import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'astrea-menu-leftnav',
  templateUrl: './menu-leftnav.component.html',
  styleUrls: ['./menu-leftnav.component.scss']
})
export class MenuLeftnavComponent implements OnInit, OnDestroy {

  @Output() handleMenu = new EventEmitter<any>();

  public isLoggedIn$!: Observable<boolean>;
  private auth$!: Subscription;

  public user!: any;
  private subsUser$!: Subscription;

  public redirect = '/home';

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authFirebaseService: AuthFirebaseService,
  ) { }

  ngOnInit(): void {
    this.userIsLoggedIn();
    this.getUserInfo();
    this.route.queryParams
      .subscribe(async (params: Params) => {
        this.redirect = params.redirect ? params.redirect : '/home';
      });
  }

  ngOnDestroy(): void {
    if (this.auth$) this.auth$.unsubscribe();
    if (this.subsUser$) this.subsUser$.unsubscribe();
  }


  userIsLoggedIn() {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn$ = of(user === null ? false : true);
      });
  }

  getUserInfo(): void {
    this.subsUser$ = this.store.select(state => state.storeUser)
      .subscribe(userData => {
        this.user = userData.user;
      });
  }

  clickHandleMenu(): void {
    this.handleMenu.emit();
  }

  logout(): void {
    this.authFirebaseService.logout();
    this.handleMenu.emit();
  }

}
