import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { cartStorageAction } from '@shared/redux/actions/actions-store-state.model';

export const CART_STORAGE: string = 'astrea-cart-storage';

export type CartStorageType = 'physical_book' | 'digital_book' ;

export interface CartStorage {
  bookcode: string;
  typeStorage: CartStorageType;
  count: number;                      // Unidades obra física, meses obras digitales
}

// Interface para unificar info de carrito e info del backend de una obra
export interface BookMerge {
  bookInfo: any | null;
  cartInfo: CartStorage;
}

@Injectable({
  providedIn: 'root'
})
export class CartStorageService {

  public cartStorage: CartStorage[] = [];

  constructor(
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.setCartStorage();
  }


  setCartStorage(): void {
    try {
      if (this.isBrowser) {
        // Step 1: Inicializamos el storage
        const strStorage = localStorage.getItem(CART_STORAGE);
        this.cartStorage = strStorage ? JSON.parse(strStorage) : null;

        // Step 2: Actualizamos cantidad
        const count = this.cartStorage.length > 0 ? this.cartStorage.length.toString().toString() : '';
        this.store.dispatch(cartStorageAction({ cartCount: count }));
      }
    }
    catch (error) {
      this.resetCart();
    }
  }

  getCartStorage(): CartStorage[] | null {
    return this.cartStorage.sort((a: CartStorage, b: CartStorage) => {
      if (a.typeStorage < b.typeStorage)
        return 1;
      if (a.typeStorage > b.typeStorage)
        return -1;
      return 0;
    });
  }

  addCartStorage(newItem: CartStorage): CartStorage[] | null {
    if (this.isBrowser) {
      // Step 1: Validamos que el item no exista
      let exist = false;
      this.cartStorage.forEach((c: CartStorage) => {
        if (c.bookcode == newItem.bookcode && c.typeStorage == newItem.typeStorage)
          exist = true;
      });

      if (!exist) {
        // Step 2: Validamos cantidad de items en el carrito
        if (this.cartStorage.length >= 15) {
          this.alertCountMax();
          throw new Error('Ha alcanzado la cantidad máxima de obras en el carrito.');
        }

        // Step 3: Agregamos item al carrito
        this.cartStorage.push(newItem);
        localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));

        // Step 4: Actualizamos cantidad
        const count = this.cartStorage.length > 0 ? this.cartStorage.length.toString().toString() : '';
        this.store.dispatch(cartStorageAction({ cartCount: count }));
      }

      return this.cartStorage;
    }
    else
      return null;
  }

  updCartStorage(bookcode: string, typeStorage: CartStorageType, updItem: CartStorage): CartStorage[] | null {
    if (this.isBrowser) {
      // Step 1: Actualizamos el item en el carrito
      this.cartStorage = this.cartStorage.map((i: CartStorage) => {
        if (i.bookcode == bookcode && i.typeStorage == typeStorage)
          return updItem;
        else
          return i;
      });
      localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));

      return this.cartStorage;
    }
    else
      return null;
  }

  delCartStorage(bookcode: string, typeStorage: CartStorageType): CartStorage[] | null {
    if (this.isBrowser) {
      // Step 1: Eliminamos item del carrito
      this.cartStorage = this.cartStorage.filter((i: CartStorage) => {
        return !(i.bookcode == bookcode && i.typeStorage == typeStorage);
      });
      localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));

      // Step 2: Actualizamos cantidad
      const count = this.cartStorage.length > 0 ? this.cartStorage.length.toString().toString() : '';
      this.store.dispatch(cartStorageAction({ cartCount: count }));

      return this.cartStorage;
    }
    else
      return null;
  }

  resetCart(): CartStorage[] | null {
    if (this.isBrowser) {
      this.cartStorage = [];
      localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));
      this.store.dispatch(cartStorageAction({ cartCount: '' }));
      return this.cartStorage;
    }
    else
      return null;
  }

  resetCartPhysical(): CartStorage[] | null {
    if (this.isBrowser) {
      this.cartStorage = this.cartStorage.filter((c: CartStorage) => {
        return c.typeStorage != 'physical_book';
      });

      localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));
      const count = this.cartStorage.length > 0 ? this.cartStorage.length.toString().toString() : '';
      this.store.dispatch(cartStorageAction({ cartCount: count }));

      return this.cartStorage;
    }
    else
      return null;
  }

  resetCartDigital(): CartStorage[] | null {
    if (this.isBrowser) {
      this.cartStorage = this.cartStorage.filter((c: CartStorage) => {
        return c.typeStorage != 'digital_book';
      });

      localStorage.setItem(CART_STORAGE, JSON.stringify(this.cartStorage));
      const count = this.cartStorage.length > 0 ? this.cartStorage.length.toString().toString() : '';
      this.store.dispatch(cartStorageAction({ cartCount: count }));

      return this.cartStorage;
    }
    else
      return null;
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  alertCountMax(): void {
    this.store.dispatch(emitAlert({
      message: 'Ha alcanzado la cantidad máxima de obras en el carrito.',
      alertType: 'danger'
    }));
  }

}
