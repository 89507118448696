import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'astreaCurrency'
})
export class AstreaCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }
    return new Intl.NumberFormat('es-ES').format(value);
  }

}
