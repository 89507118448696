<div class="astrea-menu-mobile">

    <div class="sidenav-mobile">

        <div class="d-flex j-c-between a-i-start">
            <div class="astrea-logo">
                <img class="img-responsive m-0" src="/assets/images/astrea-brand/logo_web_white.png" alt="{{ 'logo' | translate }}">
            </div>
            <button class="menu-close" (click)="clickHandleMenu()" mat-icon-button attr.aria-label="{{ 'close' | translate }}">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <mat-nav-list class="pt-0 px-2 mt-3">

            <div>

                <!-- MENU PRINCIPAL -->
                <mat-accordion>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'navigation.store' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <a routerLink="/store" (click)="clickHandleMenu()" title="{{ 'navigation.catalog' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.catalog' | translate }}</span>
                            </a>
                            <a routerLink="/authors" (click)="clickHandleMenu()" title="{{ 'navigation.authors' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.authors' | translate }}</span>
                            </a>
                            <a routerLink="/collections" (click)="clickHandleMenu()" title="{{ 'navigation.collections' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.collections' | translate }}</span>
                            </a>
                            <a routerLink="/materias" (click)="clickHandleMenu()" title="{{ 'navigation.matter' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.matter' | translate }}</span>
                            </a>
                            <a routerLink="/astreavirtual" (click)="clickHandleMenu()" title="{{ 'navigation.book_digital' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.book_digital' | translate }}</span>
                            </a>
                            <a routerLink="/doctrine" (click)="clickHandleMenu()" title="{{ 'navigation.doctrine' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.doctrine' | translate }}</span>
                            </a>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'navigation.editorial.editorial' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <a routerLink="/about/history" (click)="clickHandleMenu()" title="{{ 'navigation.editorial.history' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.history' | translate }}</span>
                            </a>
                            <a routerLink="/about/concept" (click)="clickHandleMenu()" title="{{ 'navigation.editorial.concept' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.concept' | translate }}</span>
                            </a>
                            <a routerLink="/about/proceso-editorial" (click)="clickHandleMenu()" title="{{ 'navigation.editorial.process' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.process' | translate }}</span>
                            </a>
                            <a href="/assets/about/Pautas_Astrea_2017.pdf" (click)="clickHandleMenu()" target="_blank" title="{{ 'navigation.editorial.pautas' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.pautas' | translate }}</span>
                            </a>
                            <a href="/assets/about/Referato.pdf" (click)="clickHandleMenu()" target="_blank" title="{{ 'navigation.editorial.referato' | translate }}" mat-menu-item>
                                <span>{{ 'navigation.editorial.referato' | translate }}</span>
                            </a>
                        </div>
                    </mat-expansion-panel>

                    <div class="d-flex j-c-around a-i-center mt-2">
                        <a routerLink="/recent"  (click)="clickHandleMenu()" title="{{ 'navigation.recent' | translate }}" class="mx-2">
                            {{ 'navigation.recent' | translate }}
                        </a>
                        <a routerLink="/contact"  (click)="clickHandleMenu()" title="{{ 'navigation.contact' | translate }}" class="mx-2">
                            {{ 'navigation.contact' | translate }}
                        </a>
                    </div>
                    <div>
                        <a class="d-flex j-c-center a-i-center mt-2" href="https://astreavirtual.com/login" (click)="clickHandleMenu()" target="_blank" title="{{ 'navigation.go_av' | translate }}">
                            <img src="/assets/images/astrea-brand/av_isologo_white.svg" alt="Isologo Astrea Virtual">
                            {{ 'navigation.go_av' | translate }}
                        </a>
                    </div>

                </mat-accordion>

            </div>

            <hr>

            <!-- MENU LOGIN -->
            <div class="menu-login-left" *ngIf="(isLoggedIn$ | async); else navBarLogout">
                <div class="d-flex j-c-start a-i-start flex-column">
                    <a routerLink="/profile/personal" (click)="clickHandleMenu()" title="{{ 'navigation.profile' | translate }}" tabindex="0" mat-menu-item>
                        <mat-icon attr.aria-label="{{ 'navigation.profile' | translate }}">account_circle</mat-icon>
                        {{ 'navigation.profile' | translate }}
                    </a>
                    <a routerLink="/history/purchase" (click)="clickHandleMenu()" title="{{ 'navigation.history' | translate }}" mat-menu-item>
                        <mat-icon attr.aria-label="{{ 'navigation.history' | translate }}">history</mat-icon>
                        {{ 'navigation.history' | translate }}
                    </a>
                    <a routerLink="/wishlist" (click)="clickHandleMenu()" title="{{ 'navigation.wishlist' | translate }}" mat-menu-item>
                        <mat-icon attr.aria-label="{{ 'navigation.wishlist' | translate }}">stars</mat-icon>
                        {{ 'navigation.wishlist' | translate }}
                    </a>
                    <a routerLink="/contact" (click)="clickHandleMenu()" title="{{ 'navigation.contact' | translate }}" mat-menu-item>
                        <mat-icon attr.aria-label="{{ 'navigation.contact' | translate }}">feedback</mat-icon>
                        {{ 'navigation.contact' | translate }}
                    </a>
                    <a (click)="logout()" (keydown.enter)="logout()" title="{{ 'navigation.logout' | translate }}" mat-menu-item>
                        <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.logout' | translate }}">open_in_new</mat-icon>
                        {{ 'navigation.logout' | translate }}
                    </a>
                </div>
            </div>

            <ng-template #navBarLogout>
                <!-- MENU LOGOUT -->
                <div class="d-flex j-c-around a-i-center">
                    <a [routerLink]="['/login']" [queryParams]="{ redirect: redirect }" (click)="clickHandleMenu()" title="{{ 'navigation.login' | translate }}">
                        {{ 'navigation.login' | translate }}
                    </a>
                    <a [routerLink]="['/register']" [queryParams]="{ redirect: redirect }" (click)="clickHandleMenu()" title="{{ 'navigation.register' | translate }}">
                        {{ 'navigation.register' | translate }}
                    </a>
                </div>
            </ng-template>

        </mat-nav-list>
    </div>

</div>
