<div class="astrea-book-simple h-100">
    <div class="astrea-box h-100">

        <div class="d-flex j-c-center a-i-center flex-column p-relative h-100">

            <div *ngIf="!book.available" class="astrea-without-stock">{{ 'books.sold_out' | translate }}</div>

            <a [routerLink]="['/book', book.bookcode]" class="astrea-box-active d-flex j-c-around a-i-center flex-column h-100" title="{{ book.title }}">

                <div class="w-100">
                    <img class="astrea-image img-responsive" [src]="S3Service.getImageURL(book.image)" alt="{{ book.title }}">
                </div>

                <div class="d-flex j-c-between a-i-center flex-column">
                    <h3 class="t-center">{{ book.title }}</h3>
                    <div *ngIf="authors && authors.length > 0" class="w-100">
                        <div *ngFor="let a of authors; let i = index" class="book-author">
                            <span>{{ i > 0 ? ' - ' : '' }}</span>
                            <a  [routerLink]="['/author', a.authorcode]" title="{{ 'books.details.author' | translate }}: {{ a.lastname }} {{ a.firstname }}">
                                {{ a.lastname | uppercase }}, {{ a.firstname }}
                            </a>
                        </div>
                    </div>
                    <small class="t-center mt-2">
                        <i>{{ 'books.details.year' | translate }}: <strong>{{ book.year ? book.year : '-' }}</strong></i>
                        <i *ngIf="book.pages"> | {{ 'books.details.pages' | translate }}: <strong>{{ book.pages }}</strong></i>
                        <i *ngIf="book.edition"> | {{ 'books.details.edition' | translate }}: <strong>{{ book.edition }}{{ fnService.getOrdinalNumber(book.edition) }}</strong></i>
                    </small>
                </div>

            </a>
            <div *ngIf="!isPublicDoctrine" class="d-flex j-c-around a-i-center w-100 mt-2">

                <button fxFlex="45%" *ngIf="book.available && book.price; else withoutPhysical" (click)="handlePhysicalBook()" class="d-flex j-c-center a-i-center"
                    title="{{ 'books.buy_physical' | translate }}: {{ book.title }}" mat-menu-item
                >
                    <mat-icon>shopping_cart</mat-icon>
                    {{ pricingService.getCurrency() }}{{ pricingService.getWebPricing(book) | astreaCurrency }}
                </button>
                <ng-template #withoutPhysical>
                    <button *ngIf="!loadingWish" fxFlex="45%" (click)="handleWishlist()" class="d-flex j-c-center a-i-center"
                        [ngStyle]="{ opacity: isLoggedIn ? '1' : '.5' }"
                        title="{{ 'books.sold_out' | translate }}: {{ book.title }}" mat-menu-item
                    >
                        <mat-icon>stars</mat-icon>
                        {{ 'books.details.wishlist' | translate }}
                    </button>
                    <button *ngIf="loadingWish" fxFlex="45%" class="d-flex j-c-center a-i-center" mat-menu-item>
                        <astrea-loading class="mr-2" [diameter]="20" [stroke]="2"></astrea-loading>
                        {{ 'books.details.wishlist' | translate }}
                    </button>
                </ng-template>

                <button fxFlex="45%" *ngIf="book.useAV && !book.private && book.price; else withoutDigital" (click)="handleDigitalBook()" class="d-flex j-c-center a-i-center"
                    title="{{ 'books.buy_digital' | translate }}: {{ book.title }}" mat-menu-item
                >
                    <img src="/assets/images/astrea-brand/av_isologo_white.svg" alt="Isologo Astrea Virtual">
                    {{ pricingService.getCurrency() }}{{ pricingService.getAVPricing(book) | astreaCurrency }} {{ 'every_month' | translate }}
                </button>
                <ng-template #withoutDigital>
                    <div fxFlex="45%" class="book-not-available t-center d-flex j-c-center a-i-center">
                        <p>{{ 'books.cart.without_digital' | translate }}</p>
                    </div>
                </ng-template>

            </div>
        </div>

    </div>
</div>
