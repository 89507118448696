import { Component, HostBinding, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription, of } from 'rxjs';
import { AlertNotificationComponent } from '@shared/components/alert-notification/alert-notification.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @HostBinding('class') public hostLanguage: string = '';
  @ViewChild('sidenav', { static: false }) public sidenav!: MatDrawer;

  private subsLanguage$!: Subscription;

  public isLoggedIn$!: Observable<boolean>;
  private auth$!: Subscription;
  private alert$!: Subscription;

  public devMode = 'development';

  constructor(
    private authFirebaseService: AuthFirebaseService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.userIsLoggedIn();
    this.alertNotifications();
    this.subsLanguage$ = this.store.select(state => state.storeConfig.language)
      .subscribe(l => {
        if (l == 'es-ar')
          this.hostLanguage = '';
        else if (l == 'en-us')
          this.hostLanguage = 'astrea-english';
      });
    this.devMode = environment.env;
  }

  ngOnDestroy(): void {
    if (this.subsLanguage$) this.subsLanguage$.unsubscribe();
    if (this.auth$) this.auth$.unsubscribe();
    if (this.alert$) this.alert$.unsubscribe();
  }

  userIsLoggedIn(): void {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn$ = of(user === null ? false : true);
      });
  }

  alertNotifications(): void {
    this.alert$ = this.store.select(state => state.storeAlert)
      .subscribe(alert => {
        if (alert.message && alert.alertType) {
          this.snackBar.openFromComponent(AlertNotificationComponent, {
            duration: 7500,
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: [ `astrea-alert-${alert.alertType}` ],
            data: alert.message
          });
        }
      });
  }

  handleMenu(): void {
    this.sidenav.toggle();
  }

}
