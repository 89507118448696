/* ======================================
              ACTIONS NGRX
====================================== */

import { createAction, props } from '@ngrx/store';
import { AlertType } from '@shared/data-structure/custom-types';


// ACCIONES CONFIG
export const configAction = createAction(
  '[Config] Change Default',
  props<{ darkMode: boolean, language: string, currency: string }>()
);
export const darkModeAction = createAction(
  '[Config] Change Dark Mode',
  props<{ darkMode: boolean }>()
);
export const languageAction = createAction(
  '[Config] Change Language',
  props<{ language: string }>()
);
export const currencyAction = createAction(
  '[Config] Change Currency',
  props<{ currency: string }>()
);
export const divisaAction = createAction(
  '[Config] Change Divisas',
  props<{ dollar: number, euro: number }>()
);
export const shipmentArgAction = createAction(
  '[Config] Change Shipment ARG',
  props<{ maxamount: number, shippingcost: number }>()
);
export const basepriceAction = createAction(
  '[Config] Change Shipment FX',
  props<{ baseprice: number }>()
);
export const cartStorageAction = createAction(
  '[Config] Cart Storage Count',
  props<{ cartCount: string }>()
);


// ACCIONES ALERTA
export const emitAlert = createAction(
  '[Alert] Emit Notification',
  props<{ message: string, alertType: AlertType }>()
);
export const resetAlert = createAction(
  '[Alert] Reset Notifications'
);


// ACCIONES USER
export const updateUser = createAction(
  '[User] Update',
  props<{ user: any }>()
);
export const resetUser = createAction(
  '[User] Reset'
);


// ACCIONES DATAPROVIDER
export const updateProviderRoles = createAction(
  '[DataProvider] Update Roles',
  props<{ roles: any }>()
);
export const updateProviderLM = createAction(
  '[DataProvider] Update LM',
  props<{ lm: any }>()
);


// ACCIONES COLLECTIONS
export const saveCollections = createAction(
  '[Collections] Save',
  props<{ collections: any }>()
);
