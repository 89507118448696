/* ======================================
              REDUCERS NGRX
====================================== */

import { createReducer, on } from '@ngrx/store';

import {
  configAction, darkModeAction, languageAction, currencyAction, divisaAction,
  shipmentArgAction, basepriceAction, cartStorageAction,
  emitAlert, resetAlert,
  updateUser, resetUser,
  updateProviderRoles, updateProviderLM,
  saveCollections,
} from '../actions/actions-store-state.model';
import {
  initStoreStateConfig, initStoreStateAlert, initStoreStateUser, initStoreStateDataProvider, initStoreStateCollections
} from '../store-state.model';


// REDUCERS CONFIG
export const reducerConfig = createReducer(
  initStoreStateConfig,
  on(configAction, (state, { darkMode, language, currency }) => {
    return {
      ...state,
      darkMode: darkMode,
      language: language,
      currency: currency,
    };
  }),
  on(darkModeAction, (state, { darkMode }) => {
    return {
      ...state,
      darkMode: darkMode,
    };
  }),
  on(languageAction, (state, { language }) => {
    return {
      ...state,
      language: language,
    };
  }),
  on(currencyAction, (state, { currency }) => {
    return {
      ...state,
      currency: currency,
    };
  }),
  on(divisaAction, (state, { dollar, euro }) => {
    return {
      ...state,
      dollar: dollar,
      euro: euro,
    };
  }),
  on(shipmentArgAction, (state, { maxamount, shippingcost }) => {
    return {
      ...state,
      maxamount: maxamount,
      shippingcost: shippingcost,
    };
  }),
  on(basepriceAction, (state, { baseprice }) => {
    return {
      ...state,
      baseprice: baseprice,
    };
  }),
  on(cartStorageAction, (state, { cartCount }) => {
    return {
      ...state,
      cartCount: cartCount,
    };
  }),
);


// REDUCERS ALERTA
export const reducerAlert = createReducer(
  initStoreStateAlert,
  on(emitAlert, (state, { message, alertType }) => {
    return {
      ...state,
      message, alertType,
    };
  }),
  on(resetAlert, () => {
    return initStoreStateAlert;
  })
);


// REDUCERS USER
export const reducerUser = createReducer(
  initStoreStateUser,
  on(updateUser, (state, { user }) => {
    return {
      ...state,
      user,
    };
  }),
  on(resetUser, () => {
    return initStoreStateUser;
  })
);


// REDUCERS DATAPROVIDER
export const reducerDataProvider = createReducer(
  initStoreStateDataProvider,
  on(updateProviderRoles, (state, { roles }) => {
    return {
      ...state,
      roles,
    };
  }),
  on(updateProviderLM, (state, { lm }) => {
    return {
      ...state,
      lm,
    };
  }),
);


// REDUCERS COLLECTIONS
export const reducerCollections = createReducer(
  initStoreStateCollections,
  on(saveCollections, (state, { collections }) => {
    return {
      ...state,
      collections,
    };
  }),
);
