import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { CURRENCY_WORTH } from '@shared/data-structure/currency-worth';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  private pv: number;               // Page value
  private pvryp: number;            // Page value reduction every year (4 = 4%)
  private currentYear!: number;

  private currency = 'ARS';

  private dolarPrice = CURRENCY_WORTH;
  private euroPrice = CURRENCY_WORTH;

  constructor(
    private store: Store<AppState>
  ) {
    this.pv = 8;
    this.pvryp = 4
    this.currentYear = new Date().getFullYear();
    this.store.select(state => state.storeConfig.currency)
      .subscribe(currency => {
        this.currency = currency;
      });
    this.store.select(state => state.storeConfig.dollar)
      .subscribe(dollar => {
        this.dolarPrice = dollar;
      });
    this.store.select(state => state.storeConfig.dollar)
      .subscribe(euro => {
        this.euroPrice = euro;
      });
  }

  getDollarPrice(): number {
    return this.dolarPrice;
  }

  getEuroPrice(): number {
    return this.euroPrice;
  }

  getWebPricing(book: any, paypal = false): number {
    const priceWeb = book.price;

    if (!paypal)
      return this.priceByCurrency(priceWeb);
    else
      return this.priceByCurrencyPayPal(priceWeb);
  }

  getAVPricing(book: any, paypal = false): number {

    // Definimos con Fede que el valor de alquiler por mes de un libro es el 5% (07/04/25)

    if (!paypal)
      return this.priceByCurrency(book.price * 0.05);
    else
      return this.priceByCurrencyPayPal(book.price * 0.05);

    // Algoritmo replicado en el Backend

    /* const bookYear = book.year ? parseInt(book.year) : this.currentYear;         // Default currentYear
    const bookPages = book.pages ? parseInt(book.pages) : 500;                   // Default 500 pages

    const diffYear = this.currentYear - bookYear;

    const basePercentage = this.pvryp / 100;

    let pv = this.pv;
    for (let i = 0; i < diffYear; i++) {
      pv = this.roundLikePHP(pv - (pv * basePercentage), 2);
    }

    const priceAV = Math.ceil(Math.ceil(bookPages * pv) / 20);

    // Multiplico por 3 y el algoritmo parece ajustarse, no pude replicarlo desde PHP
    if (!paypal)
      return this.priceByCurrency(priceAV * 3);
    else
      return this.priceByCurrencyPayPal(priceAV * 3); */
  }

  priceByCurrency(price: number): number {
    if (this.currency == 'ARS')
      return price;
    else if (this.currency == 'USD')
      return Math.ceil(price / this.dolarPrice);
    else if (this.currency == 'EUR')
      return Math.ceil(price / this.euroPrice);
    else
      return price;
  }

  // Función para utilizar USD por defecto en lugar de ARS
  priceByCurrencyPayPal(price: number): number {
    if (this.currency == 'ARS')
      return Math.ceil(price / this.dolarPrice);            // Si 'dolarPrice' no existe, divide por cero
    else if (this.currency == 'USD')
      return Math.ceil(price / this.dolarPrice);
    else if (this.currency == 'EUR')
      return Math.ceil(price / this.euroPrice);
    else
      return Math.ceil(price / this.dolarPrice);
  }

  getCurrency(): string {
    if (this.currency == 'ARS')
      return '$';
    else if (this.currency == 'USD')
      return 'U$D ';
    else if (this.currency == 'EUR')
      return '€UR ';
    else
      return '$';
  }

  getCurrencyName(): string {
    if (this.currency == 'ARS')
      return 'Peso Argentino';
    else if (this.currency == 'USD')
      return 'Dólar norteamericano ';
    else if (this.currency == 'EUR')
      return 'Euro ';
    else
      return 'Peso Argentino';
  }

  getCurrencyPayPal(): string {
    if (this.currency == 'USD')
      return 'USD';
    else if (this.currency == 'EUR')
      return 'EUR';
    else
      return 'USD';
  }

  // https://www.php.net/manual/es/function.round.php
  // https://stackoverflow.com/questions/53450248/how-to-round-in-javascript-like-php-do
  private roundLikePHP(num: number, dec: number) {
    var num_sign = num >= 0 ? 1 : -1;
    return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
  }

}
