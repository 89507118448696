<div class="page-purchases m-sm-4 m-2">

    <div *ngIf="redirectOK; else astreaLoading" class="page-width">

        <div class="mb-3">
            <img class="img-responsive" src="/assets/images/astrea-brand/astrea-logo-green.png" alt="{{ 'logo' | translate }}">
        </div>

        <div class="astrea-title t-center mt-3">
            <h1>{{ 'purchases.success.thanks' | translate }}</h1>
            <p>
                {{ 'purchases.success.register' | translate }}
            </p>
        </div>

        <div class="mt-4 mb-2">
            <p *ngIf="typePurchase == 'digital'">
                {{ 'purchases.success.digital' | translate }} <strong><a href="https://astreavirtual.com" target="_blank" title="Astrea Virtual">Astrea Virtual</a></strong>.
                <br>
            </p>
            <div *ngIf="typePurchase == 'digital'" class="d-flex j-c-start a-i-center mt-2">
                <mat-icon>help_outline</mat-icon>
                <p class="ml-2">
                    {{ 'cart.digital.text3' | translate }}
                </p>
            </div>
            <p *ngIf="typePurchase == 'shipment'">
                {{ 'purchases.success.dhl' | translate }}
                <br>
            </p>
        </div>

        <a routerLink="/home" class="btn btn-green mt-5" mat-button>
            {{ 'purchases.success.continue' | translate }}
        </a>

    </div>

    <ng-template #astreaLoading>
        <astrea-loading class="mt-5"></astrea-loading>
    </ng-template>

</div>
