import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { SeoMetadataService } from '@shared/services/seo-metadata.service';
import { CartStorageService } from '@shared/services/cart-storage.service';

@Component({
  selector: 'astrea-pp-success',
  templateUrl: './pp-success.component.html',
  styleUrls: ['../page-purchases.scss']
})
export class PPSuccessComponent implements OnInit {

  public redirectOK = false;

  public typePurchase!: string | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private seo: SeoMetadataService,
    private cart: CartStorageService,
  ) { }

  ngOnInit(): void {
    this.typePurchase = this.route.snapshot.paramMap.get('typePurchase');
    if (!this.typePurchase)
      this.router.navigate(['/home']);

    this.route.queryParams
      .subscribe((params: Params) => {
        if (params.paymentID) {
          this.dispatchAlertOK();
          this.redirectOK = true;

          if (this.typePurchase == 'digital')
            this.cart.resetCartDigital();
          else if (this.typePurchase == 'shipment')
            this.cart.resetCartPhysical();

        } else {
          setTimeout(() => {
            this.router.navigate(['/home']);
          }, 0);
        }
      });
    this.setMetadata();
  }

  setMetadata(): void {
    this.seo.setMetadata({
      title: 'Gracias por tu compra | Editorial Astrea',
      description: 'Pago completado con éxito.'
    });
  }

  dispatchAlertOK(): void {
    setTimeout(() => {
      this.store.dispatch(emitAlert({
        message: 'Pago completado con éxito',    // Si lo traduzco, se rompe cuando redirecciona desde Mercado Pago
        alertType: 'message'
      }));
    }, 0);
  }

}
