<div class="page-purchases m-sm-4 m-2">

    <div *ngIf="redirectOK; else astreaLoading" class="page-width">

        <div class="mb-3">
            <img class="img-responsive" src="/assets/images/astrea-brand/astrea-logo-green.png" alt="{{ 'logo' | translate }}">
        </div>

        <div class="astrea-title t-center mt-3">
            <h1>{{ 'purchases.success.thanks' | translate }}</h1>
            <p>
                {{ 'purchases.success.register' | translate }}
            </p>
        </div>

        <div class="mt-4 mb-2">
            <p *ngIf="typePurchase == 'digital'">
                {{ 'purchases.success.digital' | translate }} <strong><a href="https://astreavirtual.com" target="_blank" title="Astrea Virtual">Astrea Virtual</a></strong>.
                <br>
            </p>
            <p *ngIf="typePurchase == 'house'">
                {{ 'purchases.success.text_1' | translate }} <strong><a href="https://g.page/EditorialAstrea?share" target="_blank" title="{{ 'purchases.success.locate' | translate }}">Lavalle 1208 (C1048AAF) - CABA</a></strong> {{ 'purchases.success.text_2' | translate }}
                <br>
            </p>
            <p *ngIf="typePurchase == 'shipment'">
                {{ 'purchases.success.correo_argentino_2' | translate }}
                <br>
            </p>
        </div>

        <a routerLink="/home" class="btn btn-green mt-5" mat-button>
            {{ 'purchases.success.continue' | translate }}
        </a>

    </div>

    <ng-template #astreaLoading>
        <astrea-loading class="mt-5"></astrea-loading>
    </ng-template>

</div>
