import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { CartApiService } from '@modules/cart/cart-api.service';
import { SeoMetadataService } from '@shared/services/seo-metadata.service';
import { CartStorageService } from '@shared/services/cart-storage.service';
import { Country, getDataCountries } from '@shared/data-structure/profile-data';

@Component({
  selector: 'astrea-cash-success',
  templateUrl: './cash-success.component.html',
  styleUrls: ['../page-purchases.scss']
})
export class CashSuccessMpComponent implements OnInit {

  public redirectOK = false;

  public purchase: any;
  public purchaseId!: string;

  public countries!: Country[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: CartApiService,
    private store: Store<AppState>,
    private seo: SeoMetadataService,
    private cart: CartStorageService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        if (params.purchaseId) {
          this.purchaseId = params.purchaseId;
          this.getPurchaseCashDetails();
        } else {
          setTimeout(() => {
            this.router.navigate(['/home']);
          }, 0);
        }
      });
    this.setMetadata();
  }

  setMetadata(): void {
    this.seo.setMetadata({
      title: 'Gracias por tu compra | Editorial Astrea',
      description: 'Pago completado con éxito.'
    });
  }

  getPurchaseCashDetails(): void {
    this.api.getPurchaseCashDetails(this.purchaseId)
      .subscribe(res => {
        this.purchase = res.result.data;
        this.cart.resetCartPhysical();
        this.dispatchAlertOK();
        this.redirectOK = true;
      }, err => {
        setTimeout(() => {
          this.router.navigate(['/home']);
        }, 0);
      });
  }

  dispatchAlertOK(): void {
    setTimeout(() => {
      this.store.dispatch(emitAlert({
        message: 'Pago completado con éxito',    // Si lo traduzco, se rompe cuando redirecciona desde Mercado Pago
        alertType: 'message'
      }));
    }, 0);
  }

  getCountry(id: string): string {
    const countries: Country[] = getDataCountries();
    return countries.filter(c => { return c.value == id })[0]!.text;
  }

}
