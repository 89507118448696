import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { resetUser } from '@shared/redux/actions/actions-store-state.model';
import { JWT_TOKEN } from '@shared/data-structure/jwt-token';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthFirebaseService {

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private fireAuth: AngularFireAuth,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }


  /* ========================================
                    LOGIN
  ======================================== */

  public login(email: string, pass: string): Promise<any> {
    return this.fireAuth.signInWithEmailAndPassword(email, pass);
  }

  public logout(): void {
    if (this.isBrowser)
      localStorage.removeItem(JWT_TOKEN);
    this.fireAuth.signOut();
    setTimeout(() => {
      this.store.dispatch(resetUser());
      this.router.navigate(['/home']);
    }, 0)
  }

  public logoutWithoutRedirect(): void {
    if (this.isBrowser)
      localStorage.removeItem(JWT_TOKEN);
    this.fireAuth.signOut();
    setTimeout(() => {
      this.store.dispatch(resetUser());
    }, 0)
  }

  public removeJWT(): void {
    if (this.isBrowser)
      localStorage.removeItem(JWT_TOKEN);
  }

  public isLoggedIn() {
    return this.fireAuth.authState;
  }
  public isLoggedInPromise() {
    return this.fireAuth.authState.pipe(first()).toPromise();
  }

  public getDataSessionUser(): Observable<any> {
    return this.fireAuth.idTokenResult;
  }
  public getDataSessionUserPromise(): Promise<any> {
    return this.fireAuth.idTokenResult.pipe(first()).toPromise();
  }


  /* ========================================
              CREATE/UPDATE USER
  ======================================== */

  public createUser(email: string, pass: string): Promise<any> {
    return this.fireAuth.createUserWithEmailAndPassword(email, pass);
  }


  /* ========================================
                  PERMISSIONS
  ======================================== */

  public getPermissions(): Observable<any> {
    return this.fireAuth.idTokenResult;
  }


  /* ========================================
                 REFRESH TOKEN
  ======================================== */

  private getHttpHeaders(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  public refreshToken(): Observable<any> {
    return this.isLoggedIn()
      .pipe(
        switchMap(user => {
          return this.postRefreshToken(user!.refreshToken);
        }),
      );
  }
  public refreshTokenPromise(): Promise<any> {
    return this.isLoggedIn()
      .pipe(
        switchMap(user => {
          return this.postRefreshToken(user!.refreshToken);
        }),
        first()
      ).toPromise();
  }

  // Obtener nuevo JWT a partir del refresh_token
  public postRefreshToken(token: string): Observable<any> {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: token
    };
    return this.http.post(`https://securetoken.googleapis.com/v1/token?key=${environment.firebaseConfig.apiKey}`, body, this.getHttpHeaders());
  }


  /* ========================================
                 CHANGE PASS
  ======================================== */

  public sendEmailChangePass(email: string): Promise<void> {
    return this.fireAuth.sendPasswordResetEmail(email, { url: `${environment.BASE_URL}/login` });
  }

  public verifyPasswordResetCode(code: string): Promise<string> {
    return this.fireAuth.verifyPasswordResetCode(code);
  }

  public confirmPasswordReset(code: string, email: string): Promise<void> {
    return this.fireAuth.confirmPasswordReset(code, email);
  }


  /* ========================================
                    OTHERS
  ======================================== */

  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

}
