/* ======================================
              CONFIG NGRX
====================================== */

import { ActionReducerMap } from '@ngrx/store';
import {
  StoreStateConfig, StoreStateAlert, StoreStateUser, StoreStateDataProvider, StoreStateCollections
} from '@shared/redux/store-state.model';
import {
  reducerConfig, reducerAlert, reducerUser, reducerDataProvider, reducerCollections
} from '@shared/redux/reducers/reducers-store-state.model';


export interface AppState {
  storeConfig: StoreStateConfig;
  storeAlert: StoreStateAlert;
  storeUser: StoreStateUser;
  storeDataProvider: StoreStateDataProvider;
  storeCollections: StoreStateCollections,
}

export const reducers: ActionReducerMap<AppState> = {
  storeConfig: reducerConfig,
  storeAlert: reducerAlert,
  storeUser: reducerUser,
  storeDataProvider: reducerDataProvider,
  storeCollections: reducerCollections,
};
