import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BucketS3Service } from '@shared/services/bucket-s3.service';
import { PricingService } from '@shared/services/pricing.service';
import { FunctionsService } from '@shared/services/functions.service';
import { BookApiService } from '@modules/book/book-api.service';
import { AuthorRoleService } from '@shared/services/author-role.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { CartStorageService } from '@shared/services/cart-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'astrea-book-simple',
  templateUrl: './book-simple.component.html',
  styleUrls: ['./book-simple.component.scss']
})
export class BookSimpleComponent implements OnInit, OnDestroy {

  @Input() book: any;
  public authors!: any;

  public isPublicDoctrine = false;

  public loadingWish = false;
  private wishlistBook$: Subject<boolean> = new Subject();

  public isLoggedIn: boolean = false;
  private auth$!: Subscription;

  constructor(
    public S3Service: BucketS3Service,
    public fnService: FunctionsService,
    public pricingService: PricingService,
    private store: Store<AppState>,
    private api: BookApiService,
    private cartStorageService: CartStorageService,
    private authFirebaseService: AuthFirebaseService,
    private authorRoleService: AuthorRoleService,
    private _t: TranslateService
  ) { }

  ngOnInit(): void {
    this.isPublicDoctrine = this.book.bookcode ? this.book.bookcode.startsWith('9900') : false;
    this.buildAuthorsData();
    this.userIsLoggedIn();
    this.listenWishlistBook();

    console.log(this.book)
  }

  ngOnDestroy(): void {
    if (this.auth$) this.auth$.unsubscribe();
  }

  buildAuthorsData(): void {
    if (this.book.authors && this.book.vAuthor) {
      const authorsData = this.authorRoleService.buildAuthorsAndRoles(this.book.authors, this.book.vAuthor);
      this.authors = authorsData.slice(0, 2);
    }
  }

  userIsLoggedIn(): void {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn = user === null ? false : true;
      });
  }


  /* ********** CART ********** */

  handlePhysicalBook(): void {
    this.cartStorageService.addCartStorage({
      bookcode: this.book.bookcode,
      typeStorage: 'physical_book',
      count: 1
    });
    if (this.isLoggedIn)
      this.alertStorage();
    else
      this.alertLoginStorage();
  }

  handleDigitalBook(): void {
    this.cartStorageService.addCartStorage({
      bookcode: this.book.bookcode,
      typeStorage: 'digital_book',
      count: 3
    });
    if (this.isLoggedIn)
      this.alertStorage();
    else
      this.alertLoginStorage();
  }

  alertStorage(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.cart.add'),
      alertType: 'message'
    }));
  }

  alertLoginStorage(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.cart.add_login'),
      alertType: 'message'
    }));
  }


  /* ********** WISHLIST ********** */

  handleWishlist(): void {
    if (this.isLoggedIn)
      this.wishlistBook$.next(true);
    else
      this.alertLoginWishlist();
  }

  listenWishlistBook(): void {
    this.wishlistBook$
      .pipe(
        debounceTime(350)
      )
      .subscribe(() => {
        this.loadingWish = true;
        this.api.addWishlist({ bookcode: this.book.bookcode })
          .subscribe(res => {
            this.loadingWish = false;
            this.alertAddWishlist();
          });
      });
  }

  alertAddWishlist(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.wishlist.add'),
      alertType: 'success'
    }));
  }

  alertLoginWishlist(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.wishlist.add_login'),
      alertType: 'message'
    }));
  }

}
