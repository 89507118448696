import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { AppState } from '@shared/redux/config-store-state.model';
import {
  configAction, emitAlert, updateUser, updateProviderLM, updateProviderRoles,
  divisaAction, shipmentArgAction
} from '@shared/redux/actions/actions-store-state.model';
import { environment } from '@environments/environment';
import { CURRENCY_WORTH } from '@shared/data-structure/currency-worth';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private authFirebaseService: AuthFirebaseService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  load(): void {
    if (this.isBrowser) {
      // Load dataproviders
      this.http.get(`${environment.URL_API_AV}/api/dataprovider/web`)
        .subscribe((res: any) => {
          this.store.dispatch(updateProviderLM({ lm: res.result.legalmatter }));
          this.store.dispatch(updateProviderRoles({ roles: res.result.authorroles }));

          const dollar = res.result.currency.dollar ? res.result.currency.dollar : CURRENCY_WORTH;
          const euro = res.result.currency.euro ? res.result.currency.euro : CURRENCY_WORTH;
          this.store.dispatch(divisaAction({ dollar: dollar, euro: euro }));

          const maxamount = res.result.shipmentARG ? res.result.shipmentARG.maxamount : 0;
          const shippingcost = res.result.shipmentARG ? res.result.shipmentARG.shippingcost : 0;
          this.store.dispatch(shipmentArgAction({ maxamount: maxamount, shippingcost: shippingcost }));
        });

      // Listen and load info del usuario, luego de loguearse
      this.authFirebaseService.getDataSessionUser()
        .subscribe(user => {
          if (user) {
            setTimeout(() => {
              this.http.get(`${environment.URL_API_AV}/api/user/me`)
                .subscribe((res: any) => {
                  this.store.dispatch(updateUser({ user: res.result.data }));
                  this.store.dispatch(configAction({
                    darkMode: res.result.data.darkmode,
                    language: res.result.data.language,
                    currency: res.result.data.currency
                  }));
                }, err => {
                  /*
                    Si el usuario no existe, queda la sesión abierta.
                    Verificar acá, en ProfileComponent.getUserIfNotExist & CartComponent
                  */
                });

            }, 0);
          }
        });
    }
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

}

export function servicesOnRun(config: AppConfigService): () => void {
  return () => {
    config.load();
  };
}
