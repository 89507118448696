<div class="astrea-paginator mt-4">
    <div class="d-flex j-c-center">
        <div *ngIf="renderPages.length > 0" class="d-flex">

            <a [routerLink]="[pageRedirect]" [queryParams]="propagateQueryParams(currentPage)" disable class="paginator-item mr-2"
                [ngClass]="currentPage == 0 ? 'disabled' : ''"
            >
                <span class="d-flex j-c-center a-i-center">
                    <mat-icon>chevron_left</mat-icon>
                </span>
            </a>

            <div *ngFor="let page of renderPages" class="paginator-item d-flex j-c-center a-i-center mr-2">
                <a *ngIf="page.value != 10000000; else ellipsis" [routerLink]="[pageRedirect]" [queryParams]="propagateQueryParams(page.index + 1)"
                    [ngClass]="currentPage == page.index ? 'page-active' : ''"
                >
                    <span *ngIf="currentPage == page.index" class="d-flex j-c-center a-i-center">
                        {{ page.value }}
                    </span>
                    <span *ngIf="currentPage != page.index && page.value != 10000000" class="d-flex j-c-center a-i-center">
                        {{ page.value }}
                    </span>
                </a>
                <ng-template #ellipsis>
                    <div class="paginator-item d-flex j-c-center a-i-center">
                        <span class="ellipsis d-flex j-c-center a-i-center">
                            ...
                        </span>
                    </div>
                </ng-template>
            </div>

            <a [routerLink]="[pageRedirect]" [queryParams]="propagateQueryParams(currentPage + 2)" class="paginator-item d-flex j-c-center a-i-center"
                [ngClass]="currentPage == (countPages - 1) ? 'disabled' : ''"
            >
                <span class="d-flex j-c-center a-i-center">
                    <mat-icon>chevron_right</mat-icon>
                </span>
            </a>

        </div>
    </div>
</div>
